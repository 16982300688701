@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
}

// .container {
//   width: 100%;
//   padding-right: 15px;
//   padding-left: 15px;
//   margin-right: auto;
//   margin-left: auto;
// }

// .row {
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// }

// .col {
//   flex-basis: 0;
//   flex-grow: 1;
//   max-width: 100%;
// }

// .col-sm-6 {
//   flex: 0 0 50%;
//   max-width: 50%;
// }

// .justify-content-md-space-between {
//   justify-content: space-between !important;
// }

// .py {
//   padding-top: 1px;
//   padding-bottom: 1px;
// }

// .px {
//   padding-right: 2px;
//   padding-left: 2px;
// }

// .container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
// }

// .row {
//   display: flex;
//   justify-content: space-between;
// }

// .col {
//   flex-basis: 0;
//   flex-grow: 1;
//   max-width: 100%;
// }

// body {
//   background-color: #080808 !important;
// }
